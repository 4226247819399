<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12">
        <c-table 
          :url="'AdminWebService/get_loan_requests'" 
          :per-page=10 
          :fields="columns" 
          :columns="['register', 'phone', 'amount', 'status', 'description']"
          :filename="'Алдаатай зээлийн хүсэлт'"
          orderDirection="DESC"
          orderBy="created_date"
          hover bordered  fixed caption="" 
        ></c-table>  
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'LoanRequest.list',
  data: function () {
    return {
      columns: [
        { label: '№', key: 'index', class:"text-center" },
        // { label: 'Данс', key: 'accountNumber' },
        { label: 'Нэр', key: 'nicename', sortable: true, sort_key:'firstname' },
        { label: 'Регистр', key: 'register', sortable: true, sort_key:'register' },
        { label: 'Утас', key: 'phone', sortable: true, sort_key:'phone' },
        { label: 'Хугацаа', key: 'term'  },
        { label: 'Хүү', key: 'interest' },
        { label: 'Дүн', key: 'amount', sortable: true, sort_key:'amount', class:"text-right" },
        { label: 'Хүсэлт гаргасан огноо', key: 'createdDate', sortable: true, sort_key:'created_date' },
        { label: 'Төлөв', key: 'status', sortable: true, sort_key:'status', class:"text-center" },
        { label: 'Тайлбар', key: 'description' }
      ],
    }
  },
  created() {
    var _buttons = []
    if( this.checkPermission('admin.loan-request.edit')) {
        _buttons.push({
            icon:'fa fa-pencil',
            label:'',
            variant:'outline-primary',
            route: 'admin.loan-request.edit',
            route_param: 'id',
            check_show: {
              field: 'status',
              value: 'NEW'
            }
          });
    }
    if( _buttons.length > 0) {
      this.$data.columns.push({ label: 'Үйлдэл', key: 'actions', buttons: _buttons, class:"text-center"},)
    }
  }
}
</script>
